<template>

    <span>
      <v-layout mb-4>
        <v-flex xs10 ml-2>
          <h2 class="lime--text lighten-1 pt-3">Gerenciar Filiais - Inclusão</h2>
        </v-flex>
        <v-flex xs2 text-right mr-3>
          <v-btn
            color="blue-grey"
            class="black--text"
            :to="{name: 'branchs'}"
          >
            <v-icon dark>keyboard_arrow_left</v-icon>
            Voltar
          </v-btn>
        </v-flex>
      </v-layout>

      <form-branch :btnLabel="btnLabel"></form-branch>
    </span>
        
</template>

<script>
import FormBranchComponent from "./partial/FormBranchComponent";

export default {
  name: "AddBranchComponent",
  data () {
    return {
        btnLabel: 'Incluir'
    }
  },
  methods: {

  },
  components: {
    formBranch: FormBranchComponent,
  }
};
</script>

<style scoped>

</style>
