<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select v-model="branch.company_id" :items="loadCompanies" item-text="comp_fantasia" item-value="comp_id" label="Selecione uma empresa..." :rules="rules.companyRules" required outline></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="branch.comp_b_titulo" :rules="rules.tituloRules" label="Título" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-text-field v-model="branch.comp_b_cnpj" :rules="rules.cnpjRules" label="CNPJ" outlined required v-mask="'##.###.###/####-##'"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-text-field v-model="branch.comp_b_ie" :rules="rules.ieRules" label="I. E." outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-text-field id="cep" v-model="branch.comp_b_cep" :rules="rules.cepRules" label="CEP" v-mask="'#####-###'" outlined required @blur="getEnderecoByCEP(branch.comp_b_cep)"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="branch.comp_b_endereco" :rules="rules.enderecoRules" label="Endereço" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="5" xl="2">
                    <v-text-field v-model="branch.comp_b_numero" :rules="rules.numerocoRules" label="Número" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="branch.comp_b_bairro" :rules="rules.bairroRules" label="Bairro" autocomplete="off" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="branch.comp_b_cidade" :rules="rules.cidadeRules" label="Cidade" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="branch.comp_b_estado" :rules="rules.estadoRules" label="Estado" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field v-model="branch.comp_b_email" :rules="rules.emailRules" label="E-Mail" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="1">
                    <v-text-field v-model="branch.comp_b_prefixo" :rules="rules.prefixoRules" label="DDD" v-mask="'##'" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-text-field v-model="branch.comp_b_fone_1" name="fone_1" label="Telefone 1" v-mask="'####-####'" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-text-field v-model="branch.comp_b_fone_2" name="fone_2" label="Telefone 2" v-mask="'####-####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-text-field v-model="branch.comp_b_fone_3" name="fone_3" label="Telefone 3" v-mask="'####-####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-text-field v-model="branch.comp_b_celular" name="celular" label="Celular" v-mask="'#-####-####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-text-field v-model="branch.comp_b_whatsapp" name="WhatsApp" label="WhatsApp" v-mask="'#-####-####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="branch.comp_b_link_facebook" :rules="rules.link_facebookRules" name="link_facebook" label="Facebook" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="branch.comp_b_link_instagram" :rules="rules.link_instagramRules" name="link_instagram" label="Instagram" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-text-field v-model="branch.comp_b_link_twitter" :rules="rules.link_twitterRules" name="link_twitter" label="Twitter" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field v-model="branch.comp_b_mapa" :rules="rules.mapaRules" label="Mapa" outlined></v-text-field>
                </v-col>

                 <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                    <v-switch color="success" v-model="branch.comp_b_status" label="Status"></v-switch>
                </v-col>

                <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6" class="text-right">
                    <v-btn dark class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                        <v-icon dark class="mdi mdi-check"></v-icon> {{btnLabel}}
                    </v-btn>
                </v-col>

            </v-row>

        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'
import cep from "cep-promise";

export default {
    props: {
        branch: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    comp_b_id: '',
                    company_id: '',
                    comp_b_titulo: '',
                    comp_b_cnpj: '',
                    comp_b_ie: '',
                    comp_b_endereco: '',
                    comp_b_numero: '',
                    comp_b_bairro: '',
                    comp_b_cidade: '',
                    comp_b_estado: '',
                    comp_b_cep: '',
                    comp_b_complemento: '',
                    comp_b_caixapostal: '',
                    comp_b_email: '',
                    comp_b_prefixo: '',
                    comp_b_fone_1: '',
                    comp_b_fone_2: '',
                    comp_b_fone_3: '',
                    comp_b_celular: '',
                    comp_b_whatsapp: '',
                    comp_b_imagem: '',
                    comp_b_mapa: '',
                    comp_b_link_facebook: '',
                    comp_b_link_instagram: '',
                    comp_b_link_twitter: '',
                    comp_b_contato: '',
                    comp_b_status: '',
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    created() {
        this.$store.dispatch('loadCompanies');
    },
    computed: {
        loadCompanies() {
            return this.$store.state.companies.items.data;
        },
    },
    name: "FormBranchComponent",

    data() {
        return {
            valid: true,
            rules: {
                tituloRules: [
                    v => !!v || 'Campo Título é obrigatório',
                    v => (v && v.length <= 200) || 'Título precisa ter até 200 Caracteres'
                ],
                cepRules: [
                    v => !!v || 'Campo CEP é obrigatório',
                    v => (v && v.length <= 9) || 'CEP precisa ter 8 Caracteres'
                ],
                numeroRules: [
                    v => !!v || 'Campo Número é obrigatório',
                ],
                bairroRules: [
                    v => !!v || 'Campo Bairro é obrigatório',
                ],
                cidadeRules: [
                    v => !!v || 'Campo Cidade é obrigatório',
                ],
                estadoRules: [
                    v => !!v || 'Campo Estado é obrigatório',
                ],
                cnpjRules: [
                    v => !!v || 'Campo CNPJ é obrigatório',
                ],
                emailRules: [
                    v => !!v || 'Campo E-Mail é obrigatório',
                    v => /.+@.+/.test(v) || 'E-mail precisa ser válido'
                ],
                prefixoRules: [
                    v => !!v || 'Campo DDD é obrigatório',
                    v => (v && v.length == 2) || 'DDD deve ter 2 caracteres'
                ],
                mapaRules: [
                    v => !!v || 'Campo Mapa é obrigatório',
                ],
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateCompanyBranch' : 'storeCompanyBranch';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                this.$store.dispatch(action, this.branch)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'branchs'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
        getEnderecoByCEP(cep_filial) {
            let c = cep_filial.replace(/\D/g, '');
            if (c !== "") {

                let validacep = /^[0-9]{8}$/;

                if (validacep.test(c)) {
                    cep(c)
                        .then(response => {

                            this.branch.comp_b_endereco = response.street;
                            this.branch.comp_b_bairro = response.neighborhood;
                            this.branch.comp_b_cidade = response.city;
                            this.branch.comp_b_estado = response.state;

                        })
                        .catch(error => {
                            console.log(error)
                        })
                } else {
                    this.$swal.fire({
                        position: 'center',
                        type: 'warning',
                        title: 'Formato do CEP inválido.',
                        showConfirmButton: true,
                        timer: 3000
                    });
                }

            } else {
                this.$swal.fire({
                    position: 'center',
                    type: 'warning',
                    title: 'O CEP é requerido.',
                    showConfirmButton: true,
                    timer: 3000
                });
            }

        },
        validate() {
            if (this.$refs.form.validate()) {
                this.snackbar = true
            }
        },
    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}
</style>
